var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer",
    attrs: {
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('div', {
          ref: "sectionForm",
          staticClass: "container"
        }, [_c('div', {
          staticClass: "block m-6"
        }, [_c('h2', {
          staticClass: "title is-2"
        }, [_vm._v(_vm._s(_vm.isEditing ? 'Actualizar Sección ' + _vm.sectionForm.name : 'Nueva sección'))])]), _c('div', {
          staticClass: "columns"
        }, [_c('div', {
          staticClass: "column is-one-quarter"
        }, [_c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "section"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var valid = _ref2.valid,
                  errors = _ref2.errors;
              return [_c('b-field', {
                attrs: {
                  "label": "Título de la sección",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-input', {
                attrs: {
                  "value": "Título de la sección"
                },
                model: {
                  value: _vm.sectionForm.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.sectionForm, "name", $$v);
                  },
                  expression: "sectionForm.name"
                }
              })], 1)];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "column is-one-quarter"
        }, [_c('validation-provider', {
          ref: "categoryProvider",
          attrs: {
            "rules": "required",
            "name": "category"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var valid = _ref3.valid,
                  errors = _ref3.errors;
              return [_c('b-field', {
                attrs: {
                  "label": "Seleccionar categoría",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-autocomplete', {
                attrs: {
                  "data": _vm.categoryList,
                  "open-on-focus": true,
                  "field": "name",
                  "placeholder": "Buscar categoría...",
                  "loading": _vm.fetchingCategories,
                  "icon": "search",
                  "clearable": ""
                },
                on: {
                  "focus": function focus($event) {
                    return _vm.fetchCategories();
                  },
                  "typing": _vm.fetchCategories,
                  "input": _vm.validateCategory,
                  "select": _vm.handleSelectCategory
                },
                scopedSlots: _vm._u([{
                  key: "empty",
                  fn: function fn() {
                    return [_vm._v("Sin resultados")];
                  },
                  proxy: true
                }, {
                  key: "default",
                  fn: function fn(props) {
                    return [_c('div', {
                      staticClass: "media"
                    }, [_c('div', {
                      staticClass: "media-content"
                    }, [_vm._v(_vm._s(props.option.name))])])];
                  }
                }], null, true),
                model: {
                  value: _vm.selectedCategory,
                  callback: function callback($$v) {
                    _vm.selectedCategory = $$v;
                  },
                  expression: "selectedCategory"
                }
              })], 1)];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "column is-one-quarter",
          attrs: {
            "id": "disposition"
          }
        }, [_c('validation-provider', {
          ref: "dispositionProvider",
          attrs: {
            "rules": "required",
            "name": "disposition"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var valid = _ref4.valid,
                  errors = _ref4.errors;
              return [_c('b-field', {
                attrs: {
                  "label": "Seleccionar distribución",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-autocomplete', {
                attrs: {
                  "data": _vm.dispositions,
                  "open-on-focus": true,
                  "field": "name",
                  "placeholder": "Seleccionar distribución...",
                  "icon": "search",
                  "clearable": ""
                },
                on: {
                  "input": _vm.validateDisposition,
                  "select": _vm.handleSelectDisposition
                },
                scopedSlots: _vm._u([{
                  key: "empty",
                  fn: function fn() {
                    return [_vm._v("Sin resultados")];
                  },
                  proxy: true
                }, {
                  key: "default",
                  fn: function fn(props) {
                    return [_c('div', {
                      staticClass: "media"
                    }, [_c('div', {
                      staticClass: "media-content"
                    }, [_vm._v(_vm._s(props.option.name))])])];
                  }
                }], null, true),
                model: {
                  value: _vm.selectedDisposition,
                  callback: function callback($$v) {
                    _vm.selectedDisposition = $$v;
                  },
                  expression: "selectedDisposition"
                }
              })], 1)];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "column is-one-quarter"
        }, [_c('b-field', {
          attrs: {
            "label": "Solo visible para usuario técnico"
          }
        }, [_c('b-switch', {
          attrs: {
            "type": "is-success",
            "true-value": 3,
            "false-value": null
          },
          model: {
            value: _vm.sectionForm.requiredAccessLevel,
            callback: function callback($$v) {
              _vm.$set(_vm.sectionForm, "requiredAccessLevel", $$v);
            },
            expression: "sectionForm.requiredAccessLevel"
          }
        })], 1)], 1)]), _c('div', {
          staticClass: "columns"
        }, [_c('div', {
          staticClass: "column is-full"
        }, [_c('b-field', {
          attrs: {
            "label": "Texto de ayuda"
          }
        }, [_c('quill-editor', {
          attrs: {
            "options": _vm.quillOptions
          },
          model: {
            value: _vm.sectionForm.help,
            callback: function callback($$v) {
              _vm.$set(_vm.sectionForm, "help", $$v);
            },
            expression: "sectionForm.help"
          }
        })], 1)], 1)]), _c('div', {
          staticClass: "columns has-text-left"
        }, [_vm.questionList && _vm.questionList.length > 0 ? _c('div', {
          staticClass: "column is-full",
          attrs: {
            "id": "questions"
          }
        }, [_c('el-transfer', {
          staticClass: "question-transfer",
          attrs: {
            "data": _vm.assignQuestions(),
            "filterable": "",
            "filter-method": _vm.handleFilterQuestions,
            "titles": ['Preguntas Disponibles', 'Preguntas Seleccionadas'],
            "button-texts": ['Excluir Pregunta', 'Incluir Pregunta']
          },
          model: {
            value: _vm.selectedQuestions,
            callback: function callback($$v) {
              _vm.selectedQuestions = $$v;
            },
            expression: "selectedQuestions"
          }
        })], 1) : _vm._e()]), _c('validation-provider', {
          ref: "questionProvider",
          attrs: {
            "rules": "required|min_value:1",
            "name": "selectedQuestions"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var valid = _ref5.valid,
                  errors = _ref5.errors;
              return [_vm.questionList && _vm.questionList.length > 0 ? _c('b-field', {
                attrs: {
                  "label": "",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-input', {
                directives: [{
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }],
                model: {
                  value: _vm.selectedQuestions.length,
                  callback: function callback($$v) {
                    _vm.$set(_vm.selectedQuestions, "length", $$v);
                  },
                  expression: "selectedQuestions.length"
                }
              })], 1) : _vm._e()];
            }
          }], null, true)
        }), _c('div', {
          staticClass: "block is-align-bottom"
        }, [_c('div', {
          staticClass: "buttons is-right p-6"
        }, [_c('b-button', {
          attrs: {
            "type": "is-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.finishAddingSection();
            }
          }
        }, [_vm._v("Volver")]), _c('b-button', {
          attrs: {
            "type": "is-primary"
          },
          on: {
            "click": function click($event) {
              return handleSubmit(_vm.saveOrUpdateSection);
            }
          }
        }, [_vm._v(_vm._s(_vm.isEditing ? 'Actualizar' : 'Guardar') + " ")])], 1)])], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }