var render = function () {
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "hero is-fullheight-with-navbar is-bg-gray p-6"
  }, [_c('b-modal', {
    staticClass: "p-6",
    attrs: {
      "full-screen": ""
    },
    model: {
      value: _vm.showQuestionnairePreview,
      callback: function callback($$v) {
        _vm.showQuestionnairePreview = $$v;
      },
      expression: "showQuestionnairePreview"
    }
  }, [_vm.selectedQuestionnaire ? _c('dpt-questionnaire', {
    attrs: {
      "questionnaire-id": _vm.selectedQuestionnaire.id,
      "isPreview": _vm.isPreview
    }
  }) : _vm._e()], 1), _c('b-modal', {
    staticClass: "is-bg-main p-6",
    attrs: {
      "can-cancel": ['escape', 'x'],
      "on-cancel": _vm.resetStatus,
      "active": _vm.showImportAnswersDialog
    }
  }, [_c('import-questionnaire-answers', {
    attrs: {
      "questionnaire": _vm.selectedQuestionnaire
    },
    on: {
      "close": _vm.resetStatus
    }
  })], 1), !_vm.addQuestionnaire ? _c('div', [_c('div', {
    staticClass: "hero-head"
  }, [_c('div', {
    staticClass: "navbar"
  }, [_c('div', {
    staticClass: "container"
  }, [_vm._m(0), _c('div', {
    staticClass: "navbar-menu",
    attrs: {
      "id": "navbarMenuHeroB"
    }
  }, [_c('div', {
    staticClass: "navbar-end"
  }, [_c('span', {
    staticClass: "navbar-item"
  }, [_c('b-button', {
    attrs: {
      "type": "is-primary"
    },
    on: {
      "click": function click($event) {
        _vm.addQuestionnaire = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "plus"
    }
  }), _c('span', [_vm._v("Añadir nueva")])], 1)], 1)])])])])]), _c('div', {
    staticClass: "hero-body"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "block"
  }, [_c('section', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "block is-full"
  }, [_c('b-field', {
    attrs: {
      "grouped": "",
      "group-multiline": "",
      "position": "is-right"
    }
  }, [_c('b-field', [_c('b-input', {
    attrs: {
      "placeholder": "Buscar fichas de análisis...",
      "type": "search",
      "icon": "search"
    },
    model: {
      value: _vm.filters.questionnaireSearch,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "questionnaireSearch", $$v);
      },
      expression: "filters.questionnaireSearch"
    }
  }), _c('b-autocomplete', {
    attrs: {
      "field": "name",
      "data": _vm.companyList,
      "placeholder": "Buscar empresa..."
    },
    on: {
      "typing": _vm.fetchCompanies,
      "select": function select(option) {
        return _this.filters.selectedCompany = option.name;
      }
    },
    model: {
      value: _vm.companySearch,
      callback: function callback($$v) {
        _vm.companySearch = $$v;
      },
      expression: "companySearch"
    }
  })], 1), _c('b-field', [_c('p', {
    staticClass: "control"
  }, [_c('b-radio-button', {
    attrs: {
      "name": "visible",
      "native-value": "Todos",
      "type": "is-primary is-light"
    },
    model: {
      value: _vm.filters.active,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "active", $$v);
      },
      expression: "filters.active"
    }
  }, [_vm._v(" Todos ")])], 1), _c('p', {
    staticClass: "control"
  }, [_c('b-radio-button', {
    attrs: {
      "name": "visible",
      "native-value": "Visible",
      "type": "is-primary is-light"
    },
    model: {
      value: _vm.filters.active,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "active", $$v);
      },
      expression: "filters.active"
    }
  }, [_c('v-icon', {
    attrs: {
      "icon": "eye"
    }
  })], 1)], 1), _c('p', {
    staticClass: "control"
  }, [_c('b-radio-button', {
    attrs: {
      "name": "visible",
      "native-value": "Oculto",
      "type": "is-primary is-light"
    },
    model: {
      value: _vm.filters.active,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "active", $$v);
      },
      expression: "filters.active"
    }
  }, [_c('v-icon', {
    attrs: {
      "icon": "eye-slash"
    }
  })], 1)], 1)]), _c('b-field', [_c('p', {
    staticClass: "control"
  }, [_c('b-button', {
    staticClass: "button is-link",
    on: {
      "click": _vm.fetchQuestionnaire
    }
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "filter"
    }
  }), _vm._v(" Filtrar ")], 1)], 1)]), _c('b-field', [_c('p', {
    staticClass: "control"
  }, [_c('b-button', {
    staticClass: "button is-dark",
    on: {
      "click": _vm.clearFilters
    }
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Borrar Filtros ")], 1)], 1)])], 1)], 1), _c('div', {
    staticClass: "block m-4"
  }, [_c('b-table', {
    attrs: {
      "backend-sorting": "",
      "backend-pagination": "",
      "data": _vm.data,
      "hoverable": _vm.isHoverable,
      "loading": _vm.loadingQuestionnaire,
      "current-page": _vm.currentPage
    },
    on: {
      "sort": _vm.handleTableSort,
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      }
    }
  }, [_c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "id",
      "label": "REF",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.id) + " ")];
      }
    }], null, false, 2188296272)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "name",
      "label": "Ficha de análisis",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.name) + " ")];
      }
    }], null, false, 2982927002)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "company.name",
      "label": "Compañía",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.company.name) + " ")];
      }
    }], null, false, 141863731)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "active",
      "label": "Activo",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('v-icon', {
          staticClass: "pointer",
          attrs: {
            "icon": props.row.active ? 'check-circle' : 'times-circle'
          }
        })];
      }
    }], null, false, 1038581664)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "label": "Acciones"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Previsualizar ficha de análisis",
            "placement": "top"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "type": "primary",
            "circle": "",
            "plain": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handlePreviewQuestionnaire(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "eye"
          }
        })], 1)], 1), _c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Importar respuestas base",
            "placement": "top"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "type": "warning",
            "circle": "",
            "plain": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleImportAnswers(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "file-import"
          }
        })], 1)], 1), _c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Exportar respuestas",
            "placement": "top"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "type": "info",
            "circle": ""
          },
          on: {
            "click": function click($event) {
              return _vm.generateReport(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "file-export"
          }
        })], 1)], 1), _c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Editar",
            "placement": "top"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "type": "warning",
            "circle": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleUpdateQuestionnaire(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "pen"
          }
        })], 1)], 1), _c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Duplicar",
            "placement": "top"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "type": "primary",
            "circle": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleDuplicateQuestionnaire(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "copy"
          }
        })], 1)], 1), _c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Eliminar",
            "placement": "top"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "type": "danger",
            "circle": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleDeleteQuestionnaire(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1)], 1)];
      }
    }], null, false, 2449529172)
  })], 1)], 1), _c('div', {
    staticClass: "block"
  }, [_c('el-pagination', {
    staticClass: "mt-5",
    attrs: {
      "hide-on-single-page": true,
      "current-page": _vm.currentPage,
      "page-sizes": [20, 50, 100, 200],
      "page-size": 20,
      "layout": "total, sizes, prev, pager, next",
      "total": _vm.totalElements
    },
    on: {
      "size-change": _vm.handlePageSizeChange,
      "current-change": _vm.handlePageChange,
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      }
    }
  })], 1)])])])])]) : _vm._e(), _vm.addQuestionnaire ? _c('div', {
    attrs: {
      "id": "addQuestionnaire"
    }
  }, [_c('add-questionnaire', {
    attrs: {
      "is-editing": _vm.isEditing,
      "questionnaire": _vm.selectedQuestionnaire
    },
    on: {
      "finishedAddingQuestionnaire": _vm.handleQuestionnaireAdded
    }
  })], 1) : _vm._e(), _vm.generatingReport ? _c('b-loading', {
    staticClass: "report-loading",
    attrs: {
      "is-full-page": "",
      "v-model": _vm.generatingReport,
      "active": _vm.generatingReport,
      "can-cancel": false
    }
  }, [_c('div', {
    staticClass: "is-flex is-flex-direction-column is-align-items-center"
  }, [_c('h2', {
    staticClass: "title is-2",
    staticStyle: {
      "color": "black !important"
    }
  }, [_vm._v("Generando Informe")]), _c('b-icon', {
    attrs: {
      "pack": "fas",
      "icon": "sync-alt",
      "size": "is-large",
      "custom-class": "fa-spin"
    }
  })], 1)]) : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title"
  }, [_c('h1', [_vm._v("Fichas de análisis")])]);
}]

export { render, staticRenderFns }