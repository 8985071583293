<template>
  <validation-observer ref="observer" tag="div" v-slot="{invalid}">
    <b-modal v-model="showAddSection" class="p-6" full-screen @close="resetStatus()">
      <add-section v-if="isEditing" :section="selectedSection" :is-editing="isEditingSection"
                   :section-index="selectedSectionIndex"
                   :questionnaire-id="questionnaire.id"
                   @cancelUpsertSection="handleCancelUpsertSection"
                   @sectionSaved="handleSavedSection" @sectionUpdated="handleUpdatedSection"></add-section>
    </b-modal>
    <div class="hero-head">
      <div class="navbar">
        <div class="container">
          <div class="title">
            <h1>Nuevo cuestionario</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="hero-body">
      <div class="container has-text-left">
        <div class="box">
          <div class="title">
            <h1>Datos de la ficha de análisis</h1>
          </div>
          <div class="block">
            <validation-provider rules="required" name="name" v-slot="{valid, errors}">
              <b-field label="Nombre*" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                       :message="errors">
                <b-input value="Nombre de la ficha de análisis" v-model="questionnaireForm.name"
                         :disabled="!isEditingQuestionnaire"></b-input>
              </b-field>
            </validation-provider>
            <b-field label="Descripción">
              <b-input maxlength="200" type="textarea" placeholder="Comentarios o explicaciones"
                       v-model="questionnaireForm.description" :disabled="!isEditingQuestionnaire"></b-input>
            </b-field>
            <validation-provider ref="companyProvider" rules="required" name="company" v-slot="{valid, errors}">
              <b-field label="Seleccionar empresa*" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                       :message="errors">
                <b-autocomplete
                  :disabled="!isEditingQuestionnaire"
                  :data="companyList"
                  :open-on-focus="true"
                  @focus="fetchCompanies()"
                  field="name"
                  placeholder="Buscar Empresa..."
                  :loading="fetchingCompanies"
                  icon="search"
                  @typing="fetchCompanies"
                  @input="validateCompany"
                  clearable
                  @select="handleSelectCompany"
                  v-model="selectedCompany">
                  <template #empty>Sin resultados</template>
                  <template slot-scope="props">
                    <div class="media">
                      <div class="media-content">{{ props.option.name }}</div>
                    </div>
                  </template>
                </b-autocomplete>
              </b-field>
            </validation-provider>
          </div>
          <div class="block">
            <validation-provider rules="required" name="visible" v-slot="{errors, valid}">
              <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }"
                       :message="errors">
                <b-switch type="is-success" v-model="questionnaireForm.active" :disabled="!isEditingQuestionnaire">
                  Activo
                </b-switch>
              </b-field>
            </validation-provider>
          </div>
          <div class="buttons is-right">
            <b-button :type="!isEditingQuestionnaire ? 'is-dark' : 'is-danger'" @click="finishAddingQuestionnaire">
              {{ !isEditingQuestionnaire ? 'Volver' : 'Cancelar' }}
            </b-button>
            <b-button v-if="isEditingQuestionnaire" type="is-success" :disabled="invalid"
                      @click="saveOrUpdateQuestionnaire">{{
                isEditing ? 'Actualizar' :
                  'Guardar'
              }}
            </b-button>
            <b-button v-else type="is-warning" :disabled="invalid"
                      @click="isEditingQuestionnaire = true">Editar
            </b-button>
          </div>
        </div>
        <div class="box" v-if="isEditing && !isEditingQuestionnaire">
          <div class="columns">
            <div class="column title">
              <h1>Secciones</h1>
            </div>
            <div class="column has-text-right">
              <b-button :disabled="isEditingQuestionnaire" type="is-primary" @click="handleAddSection">
                <v-icon icon="plus" class="g-icon"></v-icon>
                Añadir Sección
              </b-button>
            </div>
          </div>
          <div class="block">
            <b-table
              default-sort="sectionOrder"
              draggable
              :data="sectionList"
              :loading="loadingSections"
              :hoverable="true"
              @dragstart="dragstart"
              @drop="drop"
              @dragover="dragover"
              @dragleave="dragleave">
              <b-table-column centered field="sectionOrder" label="Orden" sortable v-slot="props">
                {{ props.row.sectionOrder }}
              </b-table-column>
              <b-table-column centered field="section" label="Sección" sortable v-slot="props">
                {{ props.row.name }}
              </b-table-column>
              <b-table-column centered field="category" label="Categoría" sortable v-slot="props">
                {{ props.row.category.name }}
              </b-table-column>
              <b-table-column centered field="questions" label="Nº Preguntas" sortable v-slot="props">
                {{ props.row.questions }}
              </b-table-column>
              <b-table-column centered field="alignment" label="Alineación" sortable v-slot="props">
                {{ props.row.disposition.name }}
              </b-table-column>
              <b-table-column centered label="Acciones" v-slot="props">
                <el-tooltip class="item" effect="dark" content="Editar" placement="top">
                  <el-button size="mini" type="warning" circle @click="handleEditSection(props.index,props.row)">
                    <v-icon icon="pen"></v-icon>
                  </el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Eliminar" placement="top">
                  <el-button size="mini" type="danger" circle @click="handleDeleteSection(props.row)">
                    <v-icon icon="trash"></v-icon>
                  </el-button>
                </el-tooltip>
              </b-table-column>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <div class="hero-foot ">
    </div>
  </validation-observer>
</template>

<script>
import { debounce } from 'debounce'
import AddSection from '@/components/section/AddSection'

export default {
  name: 'AddQuestionnaire',
  components: { AddSection },
  props: {
    isEditing: {
      type: Boolean,
      required: true
    },
    questionnaire: {
      type: Object,
      required: false
    }
  },
  created () {
    if (this.isEditing) {
      this.fetchSections()
      this.questionnaireForm.id = this.questionnaire.id
      this.questionnaireForm.name = this.questionnaire.name
      this.questionnaireForm.company = this.questionnaire.company
      this.questionnaireForm.description = this.questionnaire.description
      this.questionnaireForm.active = this.questionnaire.active
      this.selectedCompany = this.questionnaire.company.name
      this.isEditingQuestionnaire = false
    } else {
      this.isEditingQuestionnaire = true
    }
  },
  data () {
    return {
      companyList: [],
      fetchingCompanies: false,
      isEditingSection: false,
      isEditingQuestionnaire: false,
      loadingSections: false,
      selectedCompany: null,
      selectedSection: null,
      selectedSectionIndex: null,
      questionnaireForm: {
        company: {
          id: null,
          name: null
        },
        id: null,
        name: null,
        description: null,
        active: true,
        sections: []
      },
      sectionList: [],
      showAddSection: false,
      upsertSection: false
    }
  },
  methods: {
    dragstart (payload) {
      this.draggingSection = payload.row
      this.draggingSectionIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'copy'
    },
    dragover (payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    drop (event) {
      event.event.target.closest('tr').classList.remove('is-selected')
      const sectionOrder = event.index + 1
      this.axios.put(process.env.VUE_APP_API_UPDATE_SECTION_ORDER, null, {
        params: {
          questionnaireId: this.questionnaire.id,
          sectionId: this.draggingSection.id,
          sectionOrder: sectionOrder
        }
      }).then(() => {
        this.$notify.success({
          title: 'Sección ' + this.draggingSection.name + ' actualizada',
          message: 'La sección ocupa la posición ' + sectionOrder + ' dentro del formulario'
        })
        this.fetchSections()
      })
    },
    fetchSections () {
      this.loadingSections = true
      this.axios.get(process.env.VUE_APP_API_GET_SECTION_SUMMARY, {
        params: {
          searchQuery: 'questionnaire.id:' + this.questionnaire.id
        }
      }).then(response => {
        this.sectionList = response.data
      }).catch(err => {
        console.error('No se pueden obtener las secciones', err)
      }).finally(() => {
        this.loadingSections = false
      })
    },
    finishAddingQuestionnaire () {
      if (this.isEditingQuestionnaire && this.isEditing) {
        this.isEditingQuestionnaire = false
        this.resetStatus()
      } else {
        this.$emit('finishedAddingQuestionnaire')
      }
    },
    handleAddSection () {
      this.selectedSection = null
      this.isEditingSection = false
      this.showAddSection = true
    },
    handleCancelUpsertSection () {
      this.resetStatus()
    },
    handleDeleteSection (section) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar Sección',
        message: '¿Está seguro de que quiere <b>eliminar</b> la sección?',
        confirmText: 'Eliminar sección',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.axios.delete(process.env.VUE_APP_API_DELETE_SECTION, {
            params: {
              sectionId: section.id
            }
          }).then(() => {
            this.$notify.success({
              title: 'Sección eliminada',
              message: 'La sección ha sido eliminada correctamente'
            })
            this.fetchSections()
          }).catch(err => {
            console.error('No se puede eliminar la sección', err)
            this.$notify.error({
              title: 'Error',
              message: 'No se puede eliminar la sección'
            })
          })
        }
      })
    },
    handleEditSection (sectionIndex, section) {
      this.selectedSection = section
      this.selectedSectionIndex = sectionIndex
      this.isEditingSection = true
      this.showAddSection = true
    },
    handleSelectCompany (option) {
      this.selectedCompany = option.name
      this.questionnaireForm.company.id = option.id
    },
    fetchCompanies: debounce(function (companyName) {
      this.fetchingCompanies = true
      this.axios.get(process.env.VUE_APP_API_GET_COMPANY, {
        params: {
          searchQuery: companyName && companyName !== '' ? 'name$' + companyName : null,
          page: 0,
          size: 9999
        }
      }).then(response => {
        this.companyList = response.data.content
      }).catch(err => {
        console.error('Error al obtener las empresas', err)
      }).finally(() => {
        this.fetchingCompanies = false
      })
    }),
    resetStatus () {
      this.selectedSection = null
      this.selectedSectionIndex = null
      this.isEditingSection = false
      this.showAddSection = false
    },
    handleSavedSection () {
      this.fetchSections()
      this.resetStatus()
    },
    handleUpdatedSection () {
      this.fetchSections()
      this.resetStatus()
    },
    saveQuestionnaire () {
      this.axios.post(process.env.VUE_APP_API_CREATE_QUESTIONNAIRE, this.questionnaireForm).then(() => {
        this.$notify.success({
          title: this.$i18n.t('addQuestionnaire.created.title'),
          message: this.$i18n.t('addQuestionnaire.created.message')
        })
        this.finishAddingQuestionnaire()
      }).catch(err => {
        console.error('No se ha podido crear el questionario', err)
      })
    },
    saveOrUpdateQuestionnaire () {
      if (this.isEditing) {
        this.updateQuestionnaire()
      } else {
        this.saveQuestionnaire()
      }
    },
    updateQuestionnaire () {
      this.axios.put(process.env.VUE_APP_API_UPDATE_QUESTIONNAIRE, this.questionnaireForm).then(() => {
        this.$notify.success({
          title: this.$i18n.t('addQuestionnaire.updated.title'),
          message: this.$i18n.t('addQuestionnaire.updated.message')
        })
        if (this.isEditingQuestionnaire) {
          this.$emit('finishedAddingQuestionnaire')
        }
      })
    },
    validateCompany (e) {
      this.$refs.companyProvider.validate(e)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
