var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer",
    attrs: {
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-modal', {
          staticClass: "p-6",
          attrs: {
            "full-screen": ""
          },
          on: {
            "close": function close($event) {
              return _vm.resetStatus();
            }
          },
          model: {
            value: _vm.showAddSection,
            callback: function callback($$v) {
              _vm.showAddSection = $$v;
            },
            expression: "showAddSection"
          }
        }, [_vm.isEditing ? _c('add-section', {
          attrs: {
            "section": _vm.selectedSection,
            "is-editing": _vm.isEditingSection,
            "section-index": _vm.selectedSectionIndex,
            "questionnaire-id": _vm.questionnaire.id
          },
          on: {
            "cancelUpsertSection": _vm.handleCancelUpsertSection,
            "sectionSaved": _vm.handleSavedSection,
            "sectionUpdated": _vm.handleUpdatedSection
          }
        }) : _vm._e()], 1), _c('div', {
          staticClass: "hero-head"
        }, [_c('div', {
          staticClass: "navbar"
        }, [_c('div', {
          staticClass: "container"
        }, [_c('div', {
          staticClass: "title"
        }, [_c('h1', [_vm._v("Nuevo cuestionario")])])])])]), _c('div', {
          staticClass: "hero-body"
        }, [_c('div', {
          staticClass: "container has-text-left"
        }, [_c('div', {
          staticClass: "box"
        }, [_c('div', {
          staticClass: "title"
        }, [_c('h1', [_vm._v("Datos de la ficha de análisis")])]), _c('div', {
          staticClass: "block"
        }, [_c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "name"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var valid = _ref2.valid,
                  errors = _ref2.errors;
              return [_c('b-field', {
                attrs: {
                  "label": "Nombre*",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-input', {
                attrs: {
                  "value": "Nombre de la ficha de análisis",
                  "disabled": !_vm.isEditingQuestionnaire
                },
                model: {
                  value: _vm.questionnaireForm.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.questionnaireForm, "name", $$v);
                  },
                  expression: "questionnaireForm.name"
                }
              })], 1)];
            }
          }], null, true)
        }), _c('b-field', {
          attrs: {
            "label": "Descripción"
          }
        }, [_c('b-input', {
          attrs: {
            "maxlength": "200",
            "type": "textarea",
            "placeholder": "Comentarios o explicaciones",
            "disabled": !_vm.isEditingQuestionnaire
          },
          model: {
            value: _vm.questionnaireForm.description,
            callback: function callback($$v) {
              _vm.$set(_vm.questionnaireForm, "description", $$v);
            },
            expression: "questionnaireForm.description"
          }
        })], 1), _c('validation-provider', {
          ref: "companyProvider",
          attrs: {
            "rules": "required",
            "name": "company"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var valid = _ref3.valid,
                  errors = _ref3.errors;
              return [_c('b-field', {
                attrs: {
                  "label": "Seleccionar empresa*",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-autocomplete', {
                attrs: {
                  "disabled": !_vm.isEditingQuestionnaire,
                  "data": _vm.companyList,
                  "open-on-focus": true,
                  "field": "name",
                  "placeholder": "Buscar Empresa...",
                  "loading": _vm.fetchingCompanies,
                  "icon": "search",
                  "clearable": ""
                },
                on: {
                  "focus": function focus($event) {
                    return _vm.fetchCompanies();
                  },
                  "typing": _vm.fetchCompanies,
                  "input": _vm.validateCompany,
                  "select": _vm.handleSelectCompany
                },
                scopedSlots: _vm._u([{
                  key: "empty",
                  fn: function fn() {
                    return [_vm._v("Sin resultados")];
                  },
                  proxy: true
                }, {
                  key: "default",
                  fn: function fn(props) {
                    return [_c('div', {
                      staticClass: "media"
                    }, [_c('div', {
                      staticClass: "media-content"
                    }, [_vm._v(_vm._s(props.option.name))])])];
                  }
                }], null, true),
                model: {
                  value: _vm.selectedCompany,
                  callback: function callback($$v) {
                    _vm.selectedCompany = $$v;
                  },
                  expression: "selectedCompany"
                }
              })], 1)];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "block"
        }, [_c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "visible"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors,
                  valid = _ref4.valid;
              return [_c('b-field', {
                attrs: {
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-switch', {
                attrs: {
                  "type": "is-success",
                  "disabled": !_vm.isEditingQuestionnaire
                },
                model: {
                  value: _vm.questionnaireForm.active,
                  callback: function callback($$v) {
                    _vm.$set(_vm.questionnaireForm, "active", $$v);
                  },
                  expression: "questionnaireForm.active"
                }
              }, [_vm._v(" Activo ")])], 1)];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "buttons is-right"
        }, [_c('b-button', {
          attrs: {
            "type": !_vm.isEditingQuestionnaire ? 'is-dark' : 'is-danger'
          },
          on: {
            "click": _vm.finishAddingQuestionnaire
          }
        }, [_vm._v(" " + _vm._s(!_vm.isEditingQuestionnaire ? 'Volver' : 'Cancelar') + " ")]), _vm.isEditingQuestionnaire ? _c('b-button', {
          attrs: {
            "type": "is-success",
            "disabled": invalid
          },
          on: {
            "click": _vm.saveOrUpdateQuestionnaire
          }
        }, [_vm._v(_vm._s(_vm.isEditing ? 'Actualizar' : 'Guardar') + " ")]) : _c('b-button', {
          attrs: {
            "type": "is-warning",
            "disabled": invalid
          },
          on: {
            "click": function click($event) {
              _vm.isEditingQuestionnaire = true;
            }
          }
        }, [_vm._v("Editar ")])], 1)]), _vm.isEditing && !_vm.isEditingQuestionnaire ? _c('div', {
          staticClass: "box"
        }, [_c('div', {
          staticClass: "columns"
        }, [_c('div', {
          staticClass: "column title"
        }, [_c('h1', [_vm._v("Secciones")])]), _c('div', {
          staticClass: "column has-text-right"
        }, [_c('b-button', {
          attrs: {
            "disabled": _vm.isEditingQuestionnaire,
            "type": "is-primary"
          },
          on: {
            "click": _vm.handleAddSection
          }
        }, [_c('v-icon', {
          staticClass: "g-icon",
          attrs: {
            "icon": "plus"
          }
        }), _vm._v(" Añadir Sección ")], 1)], 1)]), _c('div', {
          staticClass: "block"
        }, [_c('b-table', {
          attrs: {
            "default-sort": "sectionOrder",
            "draggable": "",
            "data": _vm.sectionList,
            "loading": _vm.loadingSections,
            "hoverable": true
          },
          on: {
            "dragstart": _vm.dragstart,
            "drop": _vm.drop,
            "dragover": _vm.dragover,
            "dragleave": _vm.dragleave
          }
        }, [_c('b-table-column', {
          attrs: {
            "centered": "",
            "field": "sectionOrder",
            "label": "Orden",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(" " + _vm._s(props.row.sectionOrder) + " ")];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "centered": "",
            "field": "section",
            "label": "Sección",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(" " + _vm._s(props.row.name) + " ")];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "centered": "",
            "field": "category",
            "label": "Categoría",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(" " + _vm._s(props.row.category.name) + " ")];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "centered": "",
            "field": "questions",
            "label": "Nº Preguntas",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(" " + _vm._s(props.row.questions) + " ")];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "centered": "",
            "field": "alignment",
            "label": "Alineación",
            "sortable": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(" " + _vm._s(props.row.disposition.name) + " ")];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "centered": "",
            "label": "Acciones"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_c('el-tooltip', {
                staticClass: "item",
                attrs: {
                  "effect": "dark",
                  "content": "Editar",
                  "placement": "top"
                }
              }, [_c('el-button', {
                attrs: {
                  "size": "mini",
                  "type": "warning",
                  "circle": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.handleEditSection(props.index, props.row);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "icon": "pen"
                }
              })], 1)], 1), _c('el-tooltip', {
                staticClass: "item",
                attrs: {
                  "effect": "dark",
                  "content": "Eliminar",
                  "placement": "top"
                }
              }, [_c('el-button', {
                attrs: {
                  "size": "mini",
                  "type": "danger",
                  "circle": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.handleDeleteSection(props.row);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "icon": "trash"
                }
              })], 1)], 1)];
            }
          }], null, true)
        })], 1)], 1)]) : _vm._e()])]), _c('div', {
          staticClass: "hero-foot "
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }