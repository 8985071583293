var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "has-background-white"
  }, [_c('div', {
    staticClass: "hero-head"
  }, [_c('div', {
    staticClass: "navbar"
  }, [_c('div', {
    staticClass: "container is-justify-content-center  mt-6"
  }, [_c('div', {
    staticClass: "block"
  }, [_c('h4', {
    staticClass: "title is-4"
  }, [_vm._v("Importar respuestas para " + _vm._s(_vm.questionnaire.name))])])])])]), _c('div', {
    staticClass: "hero-body"
  }, [_c('div', {
    staticClass: "container is-fluid has-text-left"
  }, [_c('div', {
    staticClass: "columns is-multiline is-full"
  }, [_c('div', {
    staticClass: "column is-full"
  }, [_c('h5', {
    staticClass: "title is-5"
  }, [_vm._v("Paso 1")]), _c('p', [_vm._v("En primer lugar, deberá generar y descargar la plantilla para la importación de respuestas. Esto no es necesario si ya la ha descargado anteriormente, en cuyo caso puede continuar al Paso 2")]), _c('b-button', {
    staticClass: "is-primary mt-3",
    attrs: {
      "disabled": _vm.loadingTemplate
    },
    on: {
      "click": function click($event) {
        return _vm.getTemplate();
      }
    }
  }, [_vm._v(_vm._s(_vm.loadingTemplate ? 'Generando plantilla' : 'Generar y descargar plantilla'))])], 1), _c('div', {
    staticClass: "column is-full"
  }, [_c('h5', {
    staticClass: "title is-5"
  }, [_vm._v("Paso 2")]), _c('p', [_vm._v("Una vez que haya rellenado la plantilla con las respuestas deseadas, pinche en el siguiente botón y seleccione dicho archivo.")]), _c('b-upload', {
    attrs: {
      "loading": _vm.loadingAnswers,
      "multiple:": "",
      "false": "",
      "accept": "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "drag-drop": ""
    },
    on: {
      "input": function input($event) {
        return _vm.importAnswers();
      }
    },
    model: {
      value: _vm.answersFile,
      callback: function callback($$v) {
        _vm.answersFile = $$v;
      },
      expression: "answersFile"
    }
  }, [_c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "content has-text-centered"
  }, [_c('p', [_c('b-icon', {
    attrs: {
      "icon": "upload",
      "size": "is-large"
    }
  })], 1), _c('p', [_vm._v("Arrastra o haz click para subir el archivo (máx. 10Mb)")])])])])], 1)])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }