<template>
  <validation-observer ref="observer" tag="div" v-slot="{handleSubmit}">
    <div ref="sectionForm" class="container">
      <div class="block m-6">
        <h2 class="title is-2">{{ isEditing ? 'Actualizar Sección ' + sectionForm.name : 'Nueva sección' }}</h2>
      </div>
      <div class="columns">
        <div class="column is-one-quarter">
          <validation-provider rules="required" name="section" v-slot="{valid, errors}">
            <b-field label="Título de la sección" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                     :message="errors">
              <b-input value="Título de la sección" v-model="sectionForm.name"></b-input>
            </b-field>
          </validation-provider>
        </div>
        <div class="column is-one-quarter">
          <validation-provider ref="categoryProvider" rules="required" name="category" v-slot="{valid, errors}">
            <b-field label="Seleccionar categoría" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                     :message="errors">
              <b-autocomplete
                :data="categoryList"
                :open-on-focus="true"
                @focus="fetchCategories()"
                field="name"
                placeholder="Buscar categoría..."
                :loading="fetchingCategories"
                icon="search"
                @typing="fetchCategories"
                @input="validateCategory"
                clearable
                @select="handleSelectCategory"
                v-model="selectedCategory">
                <template #empty>Sin resultados</template>
                <template slot-scope="props">
                  <div class="media">
                    <div class="media-content">{{ props.option.name }}</div>
                  </div>
                </template>
              </b-autocomplete>
            </b-field>
          </validation-provider>
        </div>
        <div id="disposition" class="column is-one-quarter">
          <validation-provider ref="dispositionProvider" rules="required" name="disposition" v-slot="{valid, errors}">
            <b-field label="Seleccionar distribución" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                     :message="errors">
              <b-autocomplete
                :data="dispositions"
                :open-on-focus="true"
                field="name"
                placeholder="Seleccionar distribución..."
                icon="search"
                @input="validateDisposition"
                @select="handleSelectDisposition"
                clearable
                v-model="selectedDisposition">
                <template #empty>Sin resultados</template>
                <template slot-scope="props">
                  <div class="media">
                    <div class="media-content">{{ props.option.name }}</div>
                  </div>
                </template>
              </b-autocomplete>
            </b-field>
          </validation-provider>
        </div>
        <div class="column is-one-quarter">
          <b-field label="Solo visible para usuario técnico">
            <b-switch type="is-success" :true-value="3" :false-value="null"
                      v-model="sectionForm.requiredAccessLevel"></b-switch>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-full">
          <b-field label="Texto de ayuda">
            <quill-editor v-model="sectionForm.help" :options="quillOptions"></quill-editor>
          </b-field>
        </div>
      </div>
      <div class="columns has-text-left">
        <div v-if="questionList && questionList.length > 0" id="questions" class="column is-full">
          <el-transfer class="question-transfer" v-model="selectedQuestions" :data="assignQuestions()"
                       filterable
                       :filter-method="handleFilterQuestions"
                       :titles="['Preguntas Disponibles', 'Preguntas Seleccionadas']"
                       :button-texts="['Excluir Pregunta', 'Incluir Pregunta']"></el-transfer>
        </div>
      </div>
      <validation-provider ref="questionProvider" rules="required|min_value:1" name="selectedQuestions"
                           v-slot="{valid, errors}">
        <b-field v-if="questionList && questionList.length > 0" label=""
                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                 :message="errors">
          <b-input v-show="false" v-model="selectedQuestions.length"></b-input>
        </b-field>
      </validation-provider>
      <div class="block is-align-bottom">
        <div class="buttons is-right p-6">
          <b-button type="is-dark" @click="finishAddingSection()">Volver</b-button>
          <b-button type="is-primary" @click="handleSubmit(saveOrUpdateSection)">{{
              isEditing ? 'Actualizar' :
                'Guardar'
            }}
          </b-button>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { debounce } from 'debounce'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'AddSection',
  props: {
    isEditing: {
      type: Boolean,
      required: true
    },
    questionnaireId: {
      type: Number,
      required: true
    },
    section: {
      type: Object,
      required: false
    },
    sectionIndex: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      categoryList: [],
      dispositions: [
        {
          id: 1,
          name: 'Una columna'
        },
        {
          id: 2,
          name: 'Dos columnas'
        },
        {
          id: 3,
          name: 'Tres columnas'
        }
      ],
      fetchingCategories: false,
      fetchingQuestions: false,
      questionList: [],
      originallySelectedQuestions: [],
      quillOptions: {
        placeholder: 'Inserte el texto de ayuda aquí',
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            ['clean']
          ]
        }
      },
      sectionForm: {
        id: null,
        category: { id: null },
        disposition: { id: null },
        help: null,
        name: null,
        questions: [],
        questionnaireId: null,
        requiredAccessLevel: null
      },
      selectedCategory: null,
      selectedDisposition: null,
      selectedQuestions: [],
      usedQuestions: []
    }
  },
  created () {
    if (this.isEditing) {
      this.fetchSection(this.section.id)
    }
  },
  methods: {
    assignQuestions () {
      return this.questionList.map(question => {
        return {
          key: question.id,
          label: question.title,
          disabled: this.usedQuestions.includes(question.id) && !this.originallySelectedQuestions.includes(question.id)
        }
      })
    },
    clearQuestions () {
      this.questionList = []
    },
    fetchCategories: debounce(function (categoryName) {
      this.fetchingCategories = true
      this.axios.get(process.env.VUE_APP_API_GET_CATEGORY, {
        params: {
          searchQuery: categoryName && categoryName !== '' ? 'name$' + categoryName : null
        }
      }).then(response => {
        this.categoryList = response.data.content
      }).catch(err => {
        console.error('Error al obtener las categorías', err)
      }).finally(() => {
        this.fetchingCategories = false
      })
    },
    500),
    async fetchQuestions (categoryId) {
      this.clearQuestions()
      this.fetchingQuestions = true
      await this.axios.get(process.env.VUE_APP_API_GET_QUESTION_NOT_USED, {
        params: {
          questionnaireId: this.questionnaireId,
          categoryId: categoryId
        }
      }).then(response => {
        this.usedQuestions = response.data.map(q => q.id)
      }).catch(err => {
        console.error('No se pueden obtener las preguntas utilizadas', err)
      })
      this.axios.get(process.env.VUE_APP_API_GET_QUESTION, {
        params: {
          searchQuery: 'category.id:' + categoryId,
          page: 0,
          size: 2000
        }
      }).then(response => {
        this.questionList = response.data.content
      }).catch(err => {
        console.error('No se pueden obtener las preguntas de la categoría {}', categoryId)
        console.error(err)
      }).finally(() => {
        this.fetchingQuestions = false
      })
    },
    async fetchSection (sectionId) {
      this.axios.get(process.env.VUE_APP_API_GET_SECTION, {
        params: {
          searchQuery: 'id:' + sectionId
        }
      }).then(response => {
        this.sectionForm = response.data[0]
        this.selectedCategory = this.sectionForm.category.name
        this.selectedQuestions = this.sectionForm.questions.map(question => question.id)
        this.originallySelectedQuestions = this.lodash.cloneDeep(this.selectedQuestions)
        this.selectedDisposition = this.dispositions.find(d => d.id === this.sectionForm.disposition.id).name
        this.fetchQuestions(this.sectionForm.category.id)
      }).catch(err => {
        console.error('No se puede obtener la seccion', err)
      })
    },
    fetchUsedQuestions (categoryId) {
      this.axios.get(process.env.VUE_APP_API_GET_QUESTION_NOT_USED, {
        params: {
          questionnaireId: this.questionnaireId,
          categoryId: categoryId
        }
      }).then(response => {
        this.usedQuestions = response.data.map(q => q.id)
      })
    },
    finishAddingSection () {
      this.$emit('sectionSaved')
    },
    handleFilterQuestions (query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
    },
    async handleSelectCategory (option) {
      if (option !== null) {
        const categoryId = option.id
        const currentCategoryId = this.sectionForm.category.id
        if (this.isEditing && currentCategoryId !== categoryId) {
          this.selectedQuestions = []
          this.sectionForm.questions = []
        }
        this.sectionForm.category = option
        await this.fetchQuestions(categoryId)
      } else {
        this.clearQuestions()
      }
    },
    handleSelectDisposition (option) {
      if (option !== null) {
        this.sectionForm.disposition = option
      }
    },
    resetStatus () {
      this.selectedQuestions = []
      this.selectedCategory = null
      this.questionList = []
      this.usedQuestions = []
    },
    saveOrUpdateSection () {
      this.sectionForm.questionnaireId = this.questionnaireId
      this.sectionForm.questions = this.selectedQuestions.map(q => {
        return { id: q }
      })
      this.$refs.observer.validate()
      if (this.isEditing) {
        this.updateSection()
      } else {
        this.saveSection()
      }
    },
    saveSection () {
      this.axios.post(process.env.VUE_APP_API_CREATE_SECTION, this.sectionForm).then(() => {
        this.$notify.success({
          title: 'Sección creada',
          message: 'La sección se ha creado correctamente'
        })
        this.$emit('sectionSaved')
      })
    },
    updateSection () {
      this.axios.put(process.env.VUE_APP_API_UPDATE_SECTION, this.sectionForm).then(() => {
        this.$notify.success({
          title: 'Sección actualizada',
          message: 'La sección ha sido actualizada correctamente'
        })
        this.$emit('sectionUpdated')
      })
    },
    validateCategory (e) {
      this.$refs.categoryProvider.validate(e)
    },

    validateDisposition (e) {
      this.$refs.dispositionProvider.validate(e)
    }
  },
  components: { quillEditor }
}
</script>

<style lang="scss" scoped>

.question-transfer {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.quill-editor {
  background: white;
}
.el-transfer ::v-deep .el-transfer-panel {
  width: min-content;
}

.el-transfer ::v-deep .el-transfer-panel__header {
  min-width: 400px;
}

</style>
