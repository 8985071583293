<template>
  <section class="has-background-white">
    <div class="hero-head">
      <div class="navbar">
        <div class="container is-justify-content-center  mt-6">
          <div class="block">
            <h4 class="title is-4">Importar respuestas para {{ questionnaire.name }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="hero-body">
      <div class="container is-fluid has-text-left">
        <div class="columns is-multiline is-full">
          <div class="column is-full">
            <h5 class="title is-5">Paso 1</h5>
            <p>En primer lugar, deberá generar y descargar la plantilla para la importación de respuestas. Esto no es
              necesario si ya la ha descargado anteriormente, en cuyo caso puede continuar al Paso 2</p>
            <b-button :disabled="loadingTemplate" class="is-primary mt-3" @click="getTemplate()">{{loadingTemplate ? 'Generando plantilla' : 'Generar y descargar plantilla' }}</b-button>
          </div>
          <div class="column is-full">
            <h5 class="title is-5">Paso 2</h5>
            <p>Una vez que haya rellenado la plantilla con las respuestas deseadas, pinche en el siguiente botón y seleccione dicho archivo.</p>
            <b-upload v-model="answersFile"
                      :loading="loadingAnswers"
                      multiple: false
                      @input="importAnswers()"
                      accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      drag-drop>
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon
                      icon="upload"
                      size="is-large">
                    </b-icon>
                  </p>
                  <p>Arrastra o haz click para subir el archivo (máx. 10Mb)</p>
                </div>
              </section>
            </b-upload>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FileSaver from 'file-saver'

export default {
  name: 'ImportAnswersQuestionnaire',
  data () {
    return {
      answersFile: null,
      loadingAnswers: false,
      loadingTemplate: false
    }
  },
  props: {
    questionnaire: { type: Object, required: true }
  },
  methods: {
    getTemplate: function () {
      this.loadingTemplate = true
      this.axios.get(process.env.VUE_APP_API_GET_ANSWER_TEMPLATE, {
        responseType: 'blob',
        params: {
          questionnaireId: this.questionnaire.id
        }
      }).then(response => {
        const filename = response.headers.filename
        const template = response.data
        FileSaver.saveAs(template, filename)
      }).catch(err => {
        console.error('Error generando la plantilla', err)
      }).finally(() => {
        this.loadingTemplate = false
      })
    },
    importAnswers () {
      console.log('Importando respuestas')
      this.loadingAnswers = true
      const formData = new FormData()
      formData.append('file', this.answersFile)
      formData.append('questionnaireId', this.questionnaire.id)
      formData.append('companyId', this.questionnaire.company.id)
      this.axios.post(process.env.VUE_APP_API_IMPORT_ANSWERS, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(() => {
        this.$notify.success({
          title: 'Respuestas importadas',
          message: 'Las respuestas han sido importadas correctamente'
        })
        this.$emit('answersImported')
      }).catch(err => {
        console.error('No se puede importar la plantilla', err)
        this.$notify.error({
          title: 'Error',
          message: 'Error en el formato de la plantilla'
        })
      }).finally(() => {
        this.loadingAnswers = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
